@use './src/common/style/global' as *;

.Title {
  font-weight: bold;
  font-size: 24px;

  @media screen and (max-width: $bp-mobile) {
    font-size: 16px;
  }
}

.Description {
  margin-top: 0.65em;
}
