$light-gray: #eee;
$white: #ffffff;
$gray: #f5f5f5;
$green: #219653;
$red: #c62828;
$gray-red: #941313;
$blue: #031435;
$gray-blue: #0d1d47;
$black: #000;

// Colors
$textColorPrimary: #000000;
$textColorSecondary: #ffffff;
$textColorAccent: #373737;

$colorPrimary: #031435;
$colorSecondary: #219653;
$colorSecondaryDark: #1d854a;
$colorAccent: #219653;

$windowBackground: #f5f5f5;
$popupBackground: #ffffff;
$subtitleBackground: #001437;
$colorShadow: rgba(0, 0, 0, 0.25);

$label-bg-gray: #e8e8e8;
$label-bg-gray-hover: #d7d7d7;
$label-text-gray: #8b8b8b;
$placeholder-text-gray: #c4c4c4;
$timestamp-text-gray: #acacac;
$timestamp-text-gray-hover: #757575;

//$fontMain: 'Helvetica', Arial, sans-serif;
$fontMain: "Roboto", Arial, sans-serif;

$z-index-nav: 5;
$z-index-header: 10;
$z-index-drop-down-menu: 20;
$z-index-modal: 30;

// Sass Screen Variables
$bp-xxl-1440: 1440px;
$bp-xl-1170: 1170px;
$bp-lg-979: 979px;
$bp-md-768: 768px;
$bp-sm-576: 576px;

$bp-mobile: 486px;
$bp-tablet: 924px;
$bp-laptop: 1024px;
$bp-large: 1440px;

:root {
  --header-height: 58px;
  --navbar-width: 19%;
  // --navbar-width: 275px;

  @media screen and (max-width: $bp-tablet) {
    --navbar-width: 70px;
  }

  @media screen and (max-width: $bp-mobile) {
    --header-height: 50px;
    --navbar-width: 50px;
  }
}
