@use './src/common/style/global' as *;

.archive {
  margin: 0;
  padding: 0;
}

.archive__item {
  list-style: none;
  text-decoration: none;
  margin: 0 0 12px 0;
  padding: 22px 50px;
  background: #FFFFFF;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 9px;
  display: grid;
  grid-template: max-content / max-content 1fr;
  gap: 24px;
  cursor: pointer;
}

.archive__content {
  grid-row: 1/2;
  grid-column: 2/3;
  margin: 0;
  padding: 0;
  display: grid;
  grid-template: max-content / max-content 1fr;
  gap: 24px;
  align-self: center;

  @media screen and (max-width: $bp-md-768) {
    grid-template: repeat(2, max-content) / max-content 1fr;
    gap: 10px;
  }

}

.archive__item:hover {
  opacity: .7;
  transition: all .5s ease;
}

.archive__img {
  grid-row: 1/2;
  grid-column: 1/2;
  display: block;
  margin: 0;
  padding: 0;
  width: 30px;
  height: 30px;
  object-fit: contain;
  align-self: center;
}

.archive__title {
  grid-row: 1/2;
  grid-column: 2/3;
  margin: 0;
  padding: 0;
  align-self: center;
  text-align: left;

  @media screen and (max-width: $bp-md-768) {
    grid-row: 2/3;
    grid-column: 1/2;
  }
}


.archive__date {
  grid-row: 1/2;
  grid-column: 1/2;
  margin: 0;
  padding: 0;
  align-self: center;
  text-align: left;
  justify-content: left;
  opacity: .5;

  @media screen and (max-width: $bp-md-768) {
    grid-row: 1/2;
    grid-column: 1/2;
  }
}

