@use './src/common/style/global' as *;

.Card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1em 0.5em;

  @media screen and (max-width: $bp-mobile) {
    padding: 0.5em;
  }
}

.CardLabel {
  font-family: $fontMain;
  text-align: center;
  font-weight: bold;
  font-size: 24px;

  @media screen and (max-width: $bp-tablet) {
    font-size: 22px;
  }

  @media screen and (max-width: $bp-mobile) {
    font-size: 18px;
  }
}

.CardValue {
  font-family: $fontMain;
  text-align: center;
  font-weight: bold;
  font-size: 38px;
  line-height: 100%;
  margin-top: 0.2em;

  min-width: 75%;

  //@media screen and (max-width: $bp-tablet) {
  //  font-size: 38px;
  //}

  @media screen and (max-width: $bp-mobile) {
    font-size: 24px;
  }
}
