@use './src/common/style/global' as *;

.list {
  width: 100%;
  display: flex;

  &_diraction {
    &_row {
      flex-direction: row;
    }

    &_column {
      flex-direction: column;
    }
  }
}
