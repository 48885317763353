@use './src/common/style/global' as *;

.article-summary {
  margin: 0 0 24px 0;
  padding: 0;
  background: #ffffff;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
}

.article-summary__title {
  padding: 0.6em 2em 0.6em 12px;
  display: inline-block;
  margin-top: 12px;
}

.article-summary__header {
}

.article-summary__body {
  padding: 12px;
}

.article-summary__info {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.article-summary__col {
}
