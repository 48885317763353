@use './src/common/style/global' as *;

.Wrapper {
  padding: 1em;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: $bp-mobile) {
    padding: 0.5em;
  }
}

.Container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 75%;

  &.fullScreen {
    width: 100%;
  }

  @media screen and (max-width: $bp-tablet) {
    width: 75%;
  }

  @media screen and (max-width: $bp-mobile) {
    width: 100%;
  }
}

.ContainerItem {
  width: 100%;
  flex: 1 0 auto;
}

.ContainerItemAction {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1em;
}

.ContainerImg {
}

.Img {
  padding: 0 2em;
  width: 10%;
}

.Title {
  text-align: center;
  font-size: 24px;
  font-weight: bold;

  @media screen and (max-width: $bp-tablet) {
    font-size: 21px;
  }

  @media screen and (max-width: $bp-mobile) {
    font-size: 18px;
  }
}

.Description {
  text-align: center;
  font-size: 18px;
  margin-top: 0.5em;

  @media screen and (max-width: $bp-tablet) {
    font-size: 16px;
  }

  @media screen and (max-width: $bp-mobile) {
    font-size: 14px;
  }
}
