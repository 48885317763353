@use './src/common/style/global' as *;

.auth-form {
  width: 100%;

  &__inner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
  }

  &__title {
  }

  &__description {
    margin-top: 16px;
  }

  &__inputs {
    margin-top: 14px;
  }

  &__input {
    & + & {
      margin-top: 12px;
    }

    &__margin-0 {
      margin: 0;
    }

    &.hidden {
      display: none;
    }
  }

  &__other {
    display: flex;
    align-items: center;
  }

  &__other-item {
    & + & {
      margin-left: 6px;
    }

    &:last-child {
      flex-shrink: 0;
    }
  }

  &__actions {
    margin-top: 16px;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
  }

  &__action {
    margin-left: 12px;
  }
}

.divider {
  margin-top: 1rem;
  margin-bottom: 1rem;
  height: 1px;
  overflow: hidden;
  background: #e9ecef;
}

.form-input {
  display: block;
  width: 100%;
}

.form-input__input {
  font-family: $fontMain;
  display: block;
  width: 100%;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  height: calc(2.25rem + 2px);

  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}

.form-input__error {
  display: none;
  font-family: $fontMain;
  font-size: 12px;
  color: red;
}

.form-input__error.active {
  display: inline-block;
}

.css-checkbox:not(:checked) ~ .auth-form__input {
  display: none;
}
