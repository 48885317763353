@use './src/common/style/global' as *;

.Wrapper {
  background: #ffffff;
  border: 1.5px solid $colorShadow;
  border-radius: 0.5em;
  min-height: 120px;
  width: 140px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  transition: background-color 200ms;
  overflow: hidden;
  position: relative;

  &:hover {
    background-color: rgba(0, 0, 0, 0.028);
  }

  &.done {
    border-color: $green;
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &.error {
    border-color: red;
  }
}

.ImgGroup {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex: 5 0 56px;
  position: relative;
}

.LabelGroup {
  margin-top: 0.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 3 0 auto;
  width: 100%;
}

.SubmitGroup {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex: 2 0 auto;
  margin: 0.25em 0;
}

.ImgFile {
  display: block;
  object-fit: contain;
}

.ImgPlaceholder {
  display: inline-block;
  visibility: visible;
  object-position: center;
  object-fit: contain;
  height: 80%;
  width: 80%;

  &.done {
    color: $green;
  }

  &.error {
    color: red;
  }
}

.ImgBackground {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.Label {
  line-height: 1;
  font-size: 14px;
  text-align: center;
  min-height: 12px;
  vertical-align: center;
  margin: auto;

  &.error {
    color: red;
  }
}

.Footer {
  padding: 8px;
}

.Skeleton {
  width: 100%;
  height: 100%;
}

.InputFile {
  display: none;
}

.Progress {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
