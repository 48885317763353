@use './src/common/style/global' as *;

.Container {
}

.BtnCashOut {
  margin: 1em 0;

  @media screen and (max-width: $bp-mobile) {
    display: block;
    margin: 1em auto;
  }
}

.Dashboard {
  margin-bottom: 1em;
}

.Table {
}
