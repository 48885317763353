@use './src/common/style/global' as *;

.Grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: -0.5em;
}

.Item {
  width: calc((100% / 4) - 1em);
  margin: 0.5em;
  flex: 1 1 250px;

  @media screen and (max-width: $bp-mobile) {
    flex-basis: 150px;
  }
}
