@use './src/common/style/global' as *;
@use "sass:math";

$border-thickness: 0.12em;
$border-color: #ced4da;
$border-color-focus: #4d90fe;
$label-font-size: 0.876em;
$main-font-size: 16px;
$btn-offset-size: -#{math.div($border-thickness, 2)};

.field {
  font-family: $fontMain;
  font-size: $main-font-size;
  position: relative;

  &.disabled {
    border-color: #941313;
    //pointer-events: none;
    //opacity: 0.5;
  }

  &.error {
    .field__input {
      padding-left: #{1.56 * 0.75em + 1em};
      border-color: red;

      &_file {
        padding: 0;
      }
    }
  }

  &.readonly {
    &:not(.disabled) {
      .endIcon,
      .actionBtn {
        cursor: pointer;
        pointer-events: auto;
      }
    }

    .field__input {
      border: 0;
      padding-left: 0;
      color: #8b8b8b;
      pointer-events: none;
      cursor: text;
    }
  }

  &.readonly.error {
    .startIcon {
      margin-left: 0;
    }

    .field__helper {
      padding-left: 0;
    }

    .field__input {
      padding-left: #{1.56 * 0.75em + 1em};

      &_file {
        padding: 0;
      }
    }
  }

  &.startIcon .field__input {
    padding-left: #{1.56 * 0.75em + 1em};

    &_file {
      padding: 0;
    }
  }

  &.endIcon .field__input {
    padding-right: #{1.56 * 0.75em + 1em};

    &_file {
      padding: 0;
    }
  }

  @media screen and (min-width: $bp-large) {
    font-size: #{get-vw($main-font-size, $bp-large)};
  }
}

.field__label {
  display: inline-block;
  font-size: inherit;
  cursor: text;
  margin-bottom: 0.5em;
}

.field__description {
  margin-bottom: 0.5em;
  overflow: hidden;
  text-overflow: ellipsis;
}

.field__icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-position: center left;
  background-size: contain;
  width: 1.38em;
  margin: 0 0.75em;
  z-index: 1;
  pointer-events: none;
}

.field__icon_start {
  left: 0;
  right: auto;
}

.field__icon_end {
  left: auto;
  right: 0;
}

.field__input {
  display: inline-block;
  width: 100%;

  color: black;
  background-color: transparent;
  border: $border-thickness solid $border-color;
  border-radius: 0.25em;
  padding: 0.512em 0.75em;
  outline: none;

  &:focus {
    border: $border-thickness solid $border-color-focus;
  }
}

.field__input_file {
  border: 0;
  padding: 0;
  color: #00000066;
}

.field__fileInput {
  display: none;
}

.field__btn {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  cursor: pointer;
  left: auto;
  right: 0;
  top: 0;
  bottom: 0;
  margin: $btn-offset-size $btn-offset-size $btn-offset-size 0;
  padding: 0 0.75em;
  background-color: $colorSecondary;
  color: $white;
  z-index: 1;
  border-radius: 0.175em;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.field__container {
  position: relative;
}

.field__helper {
  padding: 0 0.75em;
  font-size: $label-font-size;
  color: #495057;
}

.field__helper_error {
  color: red;
}

.field__helperBox {
  position: absolute;
  transform: translateY(100%);
  bottom: 0;
  left: 0;
}

.field__select {
  cursor: pointer;
  outline: 0;
}

.field__list {
  position: absolute;
  top: calc(100% + #{$border-thickness});
  left: 0;
  right: 0;
  width: 100%;

  overflow: hidden;
  outline: 0;
  box-shadow: none;
  z-index: 1;
  background-color: #fff;
  padding: 0;
  max-height: 0;
  pointer-events: none;
  opacity: 0;
  transition: max-height, opacity 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-color: transparent;

  &.isOpen {
    opacity: 1;
    max-height: 100vh;
    pointer-events: auto;
    border-color: $border-color;
  }
}

.option {
  position: relative;
  width: 100%;
  overflow: hidden;
  padding: 0.8em;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: background-color 250ms;

  & + & {
    border-top: 0.08em solid $label-bg-gray-hover;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
}

.optionAdd {
  justify-content: center;
}

.disabledField {
  pointer-events: none;
}

.errorField {
  border-color: red;
}
