@use './src/common/style/global' as *;

$border-thickness: 0.12em;
$border-color: #ced4da;
$border-color-focus: #4d90fe;
$label-font-size: 0.876em;
$main-font-size: 16px;

.textField {
  font-family: $fontMain;
  font-size: $main-font-size;

  @media screen and (min-width: $bp-large) {
    font-size: #{get-vw($main-font-size, $bp-large)};
  }

  .textField__input {
    position: relative;
    display: inline-block;
    width: 100%;

    color: black;
    background-color: transparent;
    border: $border-thickness solid $border-color;
    border-radius: 0.25em;
    padding: 0.512em 0.75em;
    outline: none;

    &:focus {
      border: $border-thickness solid $border-color-focus;
    }
  }

  label {
    font-size: 1em;
  }

  .Img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-position: center left;
    background-size: contain;
    width: 1.38em;
    margin: 0 0.75em;
    z-index: 1;
    pointer-events: none;

    &.startIcon {
      left: 0;
      right: auto;
    }

    &.endIcon {
      left: auto;
      right: 0;
    }
  }

  .actionBtn {
    position: absolute;
    top: 50%;
    left: auto;
    right: 0;
    transform: translateY(-50%);
    z-index: 1;
  }

  .startIcon ~ .textField__input {
    padding-left: #{1.56 * 0.75em + 1em};
  }

  .endIcon ~ .textField__input {
    padding-right: #{1.56 * 0.75em + 1em};
  }

  .actionBtn ~ .textField__input {
    padding-right: #{1.56 * 0.75em + 1em};
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  &.error {
    .textField__input {
      border-color: red;
    }
  }

  &.readonly {
    &:not(.disabled) {
      .endIcon,
      .actionBtn {
        cursor: pointer;
        pointer-events: auto;
      }
    }

    .textField__input {
      border: 0;
      padding-left: 0;
      color: #8b8b8b;
      pointer-events: none;
      cursor: text;
    }
  }

  &.readonly.error {
    .startIcon {
      margin-left: 0;
    }

    .textField__helper {
      padding-left: 0;
    }

    .textField__input {
      padding-left: #{1.56 * 0.75em + 1em};
    }
  }
}

.textField__input {
}

.textField__container {
  display: flex;
  position: relative;
}

.textField__skeleton {
  min-height: 1.25em;
}

.textField__helper {
  padding: 0 0.75em;
  font-size: $label-font-size;
  color: #495057;
}

.textField__helperMsgError {
  color: red;
}
