@use './src/common/style/global' as *;

$loader-color: $label-text-gray;

.loader__wrapper {
  width: 100%;
  height: 6.5em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader,
.loader:before,
.loader:after {
  background: $loader-color;
  animation: load1 1s infinite ease-in-out;
  width: 1em;
  height: 4em;

  position: absolute;
}

.loader {
  color: $loader-color;
  text-indent: -9999em;
  margin: 88px auto;
  position: relative;
  font-size: 12px;
  transform: translateZ(0);
  animation-delay: -0.16s;
}

.loader:before,
.loader:after {
  position: absolute;
  top: 0;
  content: "";
}

.loader:before {
  left: -1.5em;
  animation-delay: -0.32s;
}

.loader:after {
  left: 1.5em;
}

@-webkit-keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}

@keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
