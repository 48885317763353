@use './src/common/style/global' as *;

.TableHead {
}

.TableBody {
}

.TableCell {
}

.TableCellFirst {
  text-align: left;
}

.TableCellEnd {
  text-align: right;
}

.TableCellName {
  @media screen and (max-width: $bp-mobile) {
    display: none;
  }
}

.TableSkeleton {
  width: 100%;
}
