@use './src/common/style/global' as *;

.nav-menu__item {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0.5em;
  border-radius: 0.25em;
  cursor: pointer;
  text-decoration: none;
  flex: 1 0 auto;

  &.show {
    background-color: #d1f3ff;
  }

  &:hover {
    background-color: #d1f3ff;
  }
}

.nav-menu__img {
  object-position: center;
  object-fit: contain;
  height: 24px;
  width: 24px;

  @media screen and (max-width: $bp-tablet) {
    width: 21px;
    height: 21px;
  }

  @media screen and (max-width: $bp-mobile) {
    width: 18px;
    height: 18px;
  }
}

.nav-menu__title {
  margin-left: 8px;
  line-height: 120%;
  transform: translateY(5%);
}

.nav-menu__badge {
  top: 50%;
  right: 0;
  bottom: auto;
  left: auto;
  transform: scale(1) translate(-50%, -50%);

  @media screen and (max-width: $bp-tablet) {
    top: 0;
    right: 0;
    bottom: auto;
    left: auto;
    transform: scale(1) translate(25%, -25%);
    padding: 0 4px;
    min-width: 14px;
    height: 14px;
    font-size: 9px;
    border-radius: 7px;
  }

  @media screen and (max-width: $bp-mobile) {
  }

  &-wrapper {
    & + & {
      margin-top: 6px;
    }
  }
}

@media screen and (max-width: $bp-tablet) {
  .nav-menu__title {
    margin-left: 0;
    margin-top: 0.5em;
    line-height: 120%;
    transform: none;
    font-size: 10px;
    text-align: center;
  }

  .nav-menu__item {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0;
  }
}

@media screen and (max-width: $bp-mobile) {
  .nav-menu__item {
    margin: auto 0;
    padding: 0.25em;
    flex: 1 0 calc(100% / 4 - 6px);

    & + & {
      margin: inherit;
      margin-left: 6px;
    }
  }
}

@media screen and (max-width: 300px) {
  .nav-menu__title {
    display: none;
  }
}
