@use './src/common/style/global' as *;

.loaderWrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  opacity: .5;
}

.loaderSection {
  position: fixed;
  top: 0;
  width: 51%;
  height: 100%;
  background: #f5f5f5;
  z-index: 1000;
}

.sectionLeft {
  left: 0;
}

.sectionRight {
  right: 0;
}

/* Loaded */
.loaded {
  &.loader {
    opacity: 0;
    transition: all 0.3s ease-out;
  }

  &.sectionLeft {
    transform: translateX(-100%);
  }

  &.sectionRight {
    transform: translateX(100%);
  }

  &.loaderWrapper {
    visibility: hidden;
    pointer-events: none;
    opacity: 0;
    transition: all 0.6s ease-out;
  }
}
