@use './src/common/style/global' as *;

.Container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.Header {
  display: none;

  @media screen and (max-width: $bp-tablet) {
    display: flex;
  }
}

.List {
  flex: 1;
  overflow-y: scroll;
}

.ShowHistoryBtn {
  padding: 1em;
  margin: 0 auto;
  align-self: flex-end;
  text-align: center;
  width: 100%;
  border-top: 0.08em solid $label-bg-gray-hover;
}
