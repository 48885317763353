@use './src/common/style/global' as *;

.Wrapper {
  background-color: transparent;

  @media screen and (max-width: $bp-tablet) {
    background-color: rgba(0, 0, 0, 0.5);
  }
}

.Inner {
  --offset-top: 0;
  --offset-right: 0;
  position: absolute;
  top: var(--offset-top, 0);
  right: var(--offset-right, 0);
  bottom: 0;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: $bp-tablet) {
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
  }
}

.Content {
  background-color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.25) 1px 1px 4px;
  border-radius: 0.4em;
  max-height: calc(100% - 1.8em);
  flex: 0 1 auto;

  @media screen and (max-height: 630px) {
    max-height: 100%;
  }

  @media screen and (max-width: $bp-tablet) {
    max-height: 100%;
    flex-grow: 1;
  }
}
