@use './src/common/style/global' as *;

.Wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.Start {
  flex: 1 1 auto;
}

.End {
  flex: 0 1 auto;
}
