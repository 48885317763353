@use './src/common/style/global' as *;

.Header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5em 1em;
  border-bottom: 0.08em solid $label-bg-gray-hover;
}

.CloseBtn {
  cursor: pointer;
  display: inline-block;
  object-position: center;
  object-fit: contain;
  font-size: 1.75em;
  transition: 500ms;

  &:hover {
    transform: scale(1.15);
  }
}
