@use './src/common/style/global' as *;

.articles {
  padding: 0;
  margin: 0 0 40px 0;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  gap: 26px;
}

@media screen and (max-width: 375px) {
  .articles {
    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
    gap: 12px;
  }
}


