@use './src/common/style/global' as *;

$border-thickness: 0.12em;
$border-color: #ced4da;
$border-color-focus: #4d90fe;
$label-font-size: 0.876em;
$main-font-size: 16px;

input[type="date"]:invalid::-webkit-datetime-edit {
  color: #8e8e8e;
}

.text-field {
  font-family: $fontMain;
  font-size: $main-font-size;

  @media screen and (min-width: $bp-large) {
    font-size: #{get-vw($main-font-size, $bp-large)};
  }

  input {
    position: relative;
    display: inline-block;
    width: 100%;

    color: black;
    background-color: #fff;
    border: $border-thickness solid $border-color;
    border-radius: 0.25em;
    padding: 0.512em 0.75em;
    outline: none;

    &:focus {
      border: $border-thickness solid $border-color-focus;
    }
  }

  label {
    font-size: 1em;
  }

  img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-position: center left;
    background-size: contain;
    width: 1.38em;
    margin: 0 0.75em;
    z-index: 1;
    pointer-events: none;

    &[data-type="button"] {
      pointer-events: auto;
      cursor: pointer;
    }

    &.start-icon {
      left: 0;
      right: auto;
    }

    &.end-icon {
      left: auto;
      right: 0;
    }
  }

  .action-btn {
    position: absolute;
    top: 50%;
    left: auto;
    right: 0;
    transform: translateY(-50%);
    z-index: 1;
  }

  .start-icon ~ input {
    padding-left: #{1.56 * 0.75em + 1em};
  }

  .end-icon ~ input {
    padding-right: #{1.56 * 0.75em + 1em};
  }

  .action-btn ~ input {
    padding-right: #{1.56 * 0.75em + 1em};
  }

  &.error {
    input {
      border-color: red;
    }
  }

  &.readonly {
    .end-icon,
    .action-btn {
      cursor: pointer;
      pointer-events: auto;
    }

    input {
      border: 0;
      padding-left: 0;
      color: #8b8b8b;
      pointer-events: none;
      cursor: text;
    }
  }

  &.readonly.required {
    .start-icon {
      margin-left: 0;
    }

    input {
      padding-left: #{1.56 * 0.75em + 1em};
    }

    input::placeholder {
      color: red;
    }
  }
}

.text-field__description {
  margin-bottom: 0.25em;
}

.text-field__label {
  display: inline-block;
  font-size: inherit;
  cursor: text;
  margin-bottom: 0.25em;
}

.text-field__container {
  position: relative;
}

.text-field__helper {
  padding: 0 0.75em;
  font-size: $label-font-size;
  color: #495057;
}

.text-field__helper-msg {
}

.text-field.error .text-field__helper-msg {
  color: red;
}
