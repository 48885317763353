@use './src/common/style/global' as *;

.LinkWrapper {
  background: #ffffff;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  transition: transform 0.2s;
  cursor: pointer;
  min-height: 300px;
  display: block;
  position: relative;

  padding: 8px 18px 18px;

  &:only-child {
    max-width: calc(100% / 3 - 14px);

    @media screen and (max-width: $bp-tablet) {
      max-width: 100%;
    }
  }

  &.newArticle {
    background-color: #eeeeee;
  }

  &.isLoading {
    pointer-events: none;
    opacity: 0.9;
  }

  &:hover {
    transform: scale(1.05);
    @media screen and (max-width: $bp-tablet) {
      transform: none;
    }
  }
}

.Container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;

  &.align {
    &_center {
      align-items: center;
    }
  }
}

.Item {
  margin-bottom: 12px;

  &:first-child {
    margin-bottom: auto;
  }

  &:last-child {
    margin-bottom: 0;
    margin-top: auto;
  }
}

.article-card__img {
  object-fit: contain;
  object-position: center;
  height: 40%;
  width: 40%;
  transition: transform 0.4s;
  margin-top: auto;

  &:hover {
    transform: scale(1.2);
  }
}

.Skeleton {
  min-width: 98px;

  &.half {
    width: 42%;
  }
}
