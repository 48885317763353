@use './src/common/style/global' as *;

.spoiler {
  font-size: 16px;

  &.open {
    .spoiler__content {
      height: auto;
    }

    .spoiler__arrow-img {
      transform: rotate(180deg);
    }

    .spoiler__title {
      padding-left: 1em;
    }

    .spoiler__header {
      background-color: rgba(0, 0, 0, 0.04);
      border-radius: 0.42em;
    }
  }
}

.spoiler__header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0.5em 0;
  cursor: pointer;
  text-decoration: none;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.spoiler__img {
  object-position: center;
  object-fit: contain;
  height: 1em;
  width: 1em;
}

.spoiler__title {
  font-size: inherit;
  transition: padding-left 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.spoiler__content {
  position: relative;
  overflow: hidden;
  height: 0;
  transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.spoiler__arrow {
  margin-left: auto;
  display: flex;
  align-items: inherit;
  justify-content: inherit;

  &-img {
    width: 1em;
    height: 1em;
    display: inline-block;
    font-size: 1.5rem;
    fill: rgba(0, 0, 0, 0.54);
    transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    flex-shrink: 0;
    user-select: none;
  }
}
