@use './src/common/style/global' as *;

.Wrapper {
  margin-top: 18px;
}

.Title {
  margin-bottom: 8px;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    bottom: -8px;
    left: 0;
    right: 15%;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.25);
  }
}
