@use './src/common/style/global' as *;

.Wrapper {
  margin: 1em;
  border-radius: 0.5em;
  background-color: rgba(0, 0, 0, 0.042);
  overflow: hidden;
  margin-bottom: 0.5em;
}

.Profile {
  display: flex;
  min-height: 72px;
  width: 100%;
  padding: 0.5em;
  border-bottom: 0.08em solid $label-bg-gray-hover;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  transition: background-color 250ms;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
}

.ProfileAvatar {
  margin: 0 0.25em;
  width: 3em;
  height: 3em;
}

.ProfileItem {
  & + & {
    margin-top: 0.2em;
  }
}

.ProfileAbout {
  flex: 4 0 auto;
  padding: 0 0.5em;
}

.ProfileArrow {
  flex: 0 0 auto;
}
