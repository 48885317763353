@use './src/common/style/global' as *;

.TabBar {
  width: 100%;
  padding-bottom: 18px;

  @media screen and (max-width: $bp-tablet) {
    padding-bottom: 8px;
  }
}

.AppBarList {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
}

.TabBarTabs {
  display: flex;
  overflow-y: scroll;

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
}

.Action {
  &_back {
    margin-right: 12px;
  }

  &__title {
    font-size: 18px;
  }

  &_other {
    display: none;
    margin-left: auto;
    @media screen and (max-width: $bp-mobile) {
      display: block;
    }
  }
}

.ActionBack {
  object-fit: contain;
  object-position: center;
  width: 24px;
  height: 24px;
}

.ActionBackLink {
  height: 100%;
}

.ActionBackImg {
  font-size: 1.5em;
  height: 100%;
  color: #0d1d47;
}

.AppBarTitle {
  font-weight: bold;
  font-size: 21px;

  @media screen and (max-width: $bp-mobile) {
    font-size: 16px;
  }
}

