@use './src/common/style/global' as *;

.link-tab {
}

.link-tab__inner {
  display: flex;
  flex-direction: column;
  width: 75%;
  margin: 0 auto;

  @media screen and (max-width: $bp-tablet) {
    width: 100%;
  }
}

.link-tab__title {
}

.link-tab__subtitle {
  margin-top: 1em;
}

.link-tab__img {
  display: block;
  object-fit: contain;
  width: 75%;
  margin: 0 auto;
}

.link-tab__img-label {
  margin-top: 0.5em;
}

.link-tab__clipboard {
  margin-top: 1em;
  width: 100%;
  display: flex;
}

.clipboard {
  display: flex;
  flex-direction: column;
}

.clipboard__title {
}

.clipboard__form {
  margin: 1em 0 2em;
  display: flex;

  @media screen and (max-width: $bp-mobile) {
    flex-direction: column;
  }
}

.clipboard__field {
  flex: 5;

  @media screen and (max-width: $bp-mobile) {
    flex: 1;
    width: 100%;
  }
}

.clipboard__btn {
  margin-left: 0.5em;
  flex: 1;
  max-height: 4em;

  @media screen and (max-width: $bp-mobile) {
    width: 100%;
    margin: 0;
    margin-top: 0.8em;
  }
}
