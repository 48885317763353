@use './src/common/style/global' as *;

.Wrapper {
  padding: 1em;
}

.FieldItem {
  & + & {
    margin-top: 1em;
  }
}

.option {
  position: relative;
  width: 100%;
  overflow: hidden;
  padding: 0.8em;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: background-color 250ms;

  & + & {
    border-top: 0.08em solid $label-bg-gray-hover;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
}
