@use './src/common/style/global' as *;

.stepper-item {
  font-size: 16px;
  width: 100%;

  &:last-child .stepper-item__border:before {
    display: none;
  }

  &.disabled {
    pointer-events: none;

    .stepper-item__icon {
      background-color: rgba(0, 0, 0, 0.38);
      color: $textColorSecondary;
    }
  }

  &.complete {
    .stepper-item__icon {
      background-color: $green;
      color: $textColorSecondary;
    }
  }
}

.stepper-item__header {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.stepper-item__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  border-radius: 50%;
  transition: background-color 0.3s 0s;
  width: 1.85em;
  height: 1.85em;
  flex: 0 0 1.85em;

  background-color: rgba(0, 0, 0, 0.38);
  color: $textColorSecondary;

  //&::before {
  //  counter-increment: section; /* Инкрементирует счётчик*/
  //  content: counter(section);
  //}
}

.stepper-item__title {
  margin-left: 0.5em;
}

.stepper-item__content {
  display: flex;
  width: 100%;
}

.stepper-item__border {
  min-height: 24px;
  position: relative;
  flex: 0 0 1.85em;
  margin: 0.42em 0;

  &:before {
    content: "";
    height: 100%;
    opacity: 1;
    width: 1px;
    background: #bdbdbd;
    position: absolute;
    left: calc(50% - 2px);
    top: 0;
    border-radius: 15px;
  }
}

.stepper-item.open .stepper-item__inner {
  height: auto;
  overflow: visible;
  padding-bottom: 3em;
  padding-top: 0.42em;
}

.stepper-item__inner {
  overflow: hidden;
  padding-left: 0.5em;
  transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  height: 0;
  width: 100%;

  @media screen and (max-width: $bp-tablet) {
    padding-left: 0;
  }
}

@media screen and (max-width: $bp-tablet) {
  .stepper-item.open .stepper-item__border {
    flex-basis: 0;

    &:before {
      display: none;
    }
  }
}

.article-stage {
  display: none;
}

.article-stage_active {
  display: block;
}

.stepper-item__border {
  min-height: 24px;
  position: relative;
  flex: 0 0 1.85em;
  margin: 0.42em 0;

  &:before {
    content: "";
    height: 100%;
    opacity: 1;
    width: 1px;
    background: #bdbdbd;
    position: absolute;
    left: calc(50% - 2px);
    top: 0;
    border-radius: 15px;
  }
}

.stepper-item__content {
  display: flex;
  width: 100%;
}
