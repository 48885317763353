@use './src/common/style/global' as *;

.ItemWrapper {
  padding: 0.8em 1em;
  width: 100%;
  position: relative;

  &:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.04);
  }

  &:not(:last-child):after {
    position: absolute;
    content: "";
    left: 0;
    right: 0;
    bottom: 0;
    border-bottom: 0.08em solid $label-bg-gray-hover;
  }

  @media screen and (max-width: $bp-mobile) {
    padding: 0.8em 0.5em;
  }
}

.Accordion {
  position: relative;
  margin: 0;
  border-left: 2px solid salmon;
  display: flex;
  flex-direction: column;
  width: 100%;

  &.isRead {
    border-color: $colorShadow;
  }
}

.AccordionHeader {
  padding: 0.5em;
}

.AccordionSubHeader {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.AccordionBody {
  overflow: hidden;
  max-height: 1000px;
  transition: max-height 1s ease-in-out;
  cursor: initial;
  will-change: max-height;

  &[aria-expanded="true"] {
    max-height: 0px;
    transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
  }
}

.AccordionInner {
  padding: 0.5em;
  padding-top: 0;
  margin-top: 0.3em;
}

.Skeleton {
  min-width: 98px;
}
