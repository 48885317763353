@use './src/common/style/global' as *;

.Dialog {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;

  @media screen and (max-width: $bp-tablet) {
    width: 80%;
  }

  @media screen and (max-width: $bp-mobile) {
    width: 100%;
  }
}

.Content {
  background-color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.25) 1px 1px 4px;
  border-radius: 0.4em;
  max-height: calc(100% - 1.8em);
  min-height: 120px;
  flex: 0 1 auto;
  width: 100%;

  @media screen and (max-height: 630px) {
    max-height: 100%;
  }

  @media screen and (max-width: $bp-tablet) {
    max-height: 100%;
    flex-grow: 1;
  }
}
