@use './src/common/style/global' as *;

.header {
  background: $white;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
  height: 100%;
  width: 100%;

  &__burger {
    display: none;
  }

  &__logo {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    height: 100%;

    @include less-than(465px) {
      justify-content: center;
    }
  }

  &__action {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    align-self: flex-end;
    height: 100%;
  }

  &__action-item {
    display: flex;
    align-items: center;
    cursor: pointer;
    border-radius: 0.3em;
    padding: 0.25em;
    position: relative;

    & + & {
      margin-left: 1em;

      &:before {
        position: absolute;
        content: "";
        top: 0;
        bottom: 0;
        left: -0.5em;
        border-left: 1px solid #c4c4c4;
      }
    }

    &:hover {
      background-color: #d1f3ff;
    }
  }
}

.header__username {
  margin-left: 0.5em;
}

.header__inner {
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 100%;
  justify-content: space-between;
  overflow: hidden;
  width: 100%;
}

.doter {
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 30px;
  justify-content: center;
  width: 30px;

  &__dot {
    align-items: center;
    background: $black;
    border-radius: 50%;
    display: flex;
    flex: none;
    height: 6px;
    justify-content: center;
    position: relative;
    transition: all 0.4s ease;
    width: 6px;

    &::before,
    &::after {
      background: inherit;
      border-radius: 50%;
      content: "";
      height: 6px;
      position: absolute;
      top: -10px;
      width: 6px;
      z-index: 1;
    }

    &::after {
      top: 10px;
    }
  }
}

.header-menu__notification {
}

.header-menu__profile {
  width: 25%;
  min-width: 320px;

  @media screen and (max-width: $bp-tablet) {
    min-width: 280px;
    width: 100%;
  }
}
