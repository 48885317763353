@use './src/common/style/global' as *;

.Wrapper {
  background: #ffffff;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  transition: transform 0.2s;
  cursor: pointer;

  padding: 18px;

  &:only-child {
    max-width: calc(100% / 3 - 14px);

    @media screen and (max-width: $bp-tablet) {
      max-width: 100%;
    }
  }
}

.isLoading {
  pointer-events: none;
  opacity: 0.3;
}

.BtnGroup {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;

  & > button {
    width: calc(50% - 0.5em);
  }
}

.FileGroup {
  display: flex;
  width: 100%;
  align-items: center;
  margin: 1em 0;
}

.Container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.Item {
  margin-bottom: 12px;

  &:first-child {
    margin-bottom: auto;
  }

  &:last-child {
    margin-bottom: 0;
    margin-top: auto;
  }
}

.PdfImg {
  display: inline-block;
  object-fit: contain;
  object-position: center;
  width: 14%;
  margin-right: 0.5em;
}
