@use './src/common/style/global' as *;

.menu {
  background: #ffffff;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.menu__inner {
  padding: 16px 0px;
}

.menu__bottom {
  border-top: 1px solid rgba(0, 27, 57, 0.08);
  margin-top: auto;
  position: relative;
  width: 100%;
  padding: 27px 34px;
}

.menu__bottom-img {
  position: absolute;
  top: -1px;
  left: 4%;
  display: block;
  object-fit: contain;
  object-position: left bottom;
  width: 40%;
  transform: translateY(-100%) rotateY(180deg);
}

.menu__bottom-btn {
  width: 100%;
  margin: auto;
}

@media screen and (max-width: $bp-tablet) {
  .menu__inner {
    padding: 0;
  }

  .menu__bottom {
    display: none;
  }
}

@media screen and (max-width: $bp-mobile) {
  .menu__inner {
    padding: 0;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
}

@media screen and (max-height: 786px) {
  .menu__bottom-img {
    display: none;
  }
}
