@use '../global' as *;

.bell {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
  height: 100%;
  cursor: pointer;

  &__img {
    display: inline-block;
    object-position: center;
    object-fit: contain;
    font-size: 30px;
  }
}

.amount {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  text-align: center;

  &__title {
    @media screen and (max-width: 450px) {
      line-height: 110%;
    }
  }

  &__desc {
    @media screen and (max-width: 450px) {
      font-size: 12px;
    }
  }
}
