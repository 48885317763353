@use './src/common/style/global' as *;

.settings {
}

.settings__subtitle {
  margin-bottom: 1em;
}

.settings__form {
}

.settings__avatarGroup {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: $bp-tablet) {
    flex-direction: column;
    align-items: stretch;
  }
}

.settings__avatarForm {
  flex: 4 1 auto;
}

.settings__avatar {
  display: flex;
  margin-bottom: 1em;
  padding: 2em;
  flex: 1 0 auto;
  max-width: 24%;
  min-width: 220px;

  @media screen and (max-width: $bp-tablet) {
    width: 100%;
    max-width: initial;
    min-width: auto;
    padding: 1em;
  }
}

.settings__input {
  margin-bottom: 1em;
}

.settings__driver {
  border-top: 1px solid rgba(196, 196, 196, 0.3);
  padding: 0;
  margin: 0.8em 0;
}
