@use './src/common/style/global' as *;

.brief-form {
  margin: 12px 0;

  &.disabled {
    opacity: 0.42;
    position: relative;
    //pointer-events: none;

    &:before {
      content: "";
      position: absolute;
      z-index: 2;
      width: 100%;
      height: 100%;
    }

    &:after {
      content: "Обновляется...";
      position: absolute;
      color: #8b8b8b;
      opacity: 100%;
      z-index: 2;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
    }
  }
}

.brief-form__description {
  margin: 1em auto;
}

.brief-form__title {
  margin: 1em 0;
}

.brief-form__input {
  & + & {
    margin-top: 1em;
  }
}

.brief-form__toggle {
  & + & {
    margin-top: 0.5em;
  }
}

.brief-form__submit {
  display: block;
  margin: 2em 0 1em auto;
}
