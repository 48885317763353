@use './src/common/style/global' as *;

.Wrapper {
  padding: 1em;
}

.FieldItem {
  & + & {
    margin-top: 1em;
  }
}

.SubmitBtn {
  margin-left: auto;
  display: flex;
  align-items: center;
}

.Error {
  margin-top: 1em;
}
