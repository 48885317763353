@use './src/common/style/global' as *;

.Checkbox {
  display: none;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;

  &:checked ~ .Circle {
    border-color: #0f9d58;
    background-color: #0f9d58;
    left: calc(100% - var(--space, 12px));
    transform: translate(-100%, -50%);
  }
}

.Circle {
  top: 50%;
  left: var(--space, 12px);
  width: 18px;
  height: 18px;
  border: solid 2px #5a5a5a;
  border-radius: 50%;
  transition: all 200ms ease-in;
  background-color: #ffffff;
  position: absolute;
  transform: translate(0, -50%);
  will-change: left;
}

.Toggle {
  --space: 12px;

  word-wrap: break-word;
  cursor: pointer;
  position: relative;

  width: 58px;
  height: 38px;
  overflow: hidden;
  z-index: 0;
  padding: var(--space, 12px);

  &::before {
    display: block;
    content: "";
    background-color: $colorShadow;
    opacity: 0.5;
    transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    height: 100%;
    width: 100%;
    border-radius: 7px;
    z-index: -1;
  }

  &.loading {
    pointer-events: none;
    opacity: 0.5;

    & .Circle {
      border-color: darkorange;
      background-color: darkorange;
      left: calc(55% - var(--space, 12px));
      transform: translate(0, -50%);
    }
  }
}

.Label {
  margin-left: 0.5em;
}

.Wrapper {
  display: inline-flex;
  align-items: center;
  flex: 0 0 auto;
}
