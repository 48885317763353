@use './src/common/style/global' as *;

.Tab {
  margin-top: 12px;

  &:not(:last-child) {
    margin-right: 12px;
  }
}

@media screen and (max-width: $bp-mobile) {
  .Tab {
    margin-top: 8px;

    &:not(:last-child) {
      margin-right: 18px;
    }
  }
}
