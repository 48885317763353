@use './src/common/style/global' as *;

.logo-banner {
  align-items: center;
  align-content: center;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;

  &__img {
    object-position: center;
    object-fit: contain;
    width: 100%;
  }

  &__link {
    margin-top: 42px;
  }
}
