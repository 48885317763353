@use './src/common/style/global' as *;

.Wrapper {
  padding: 1em;
}

.FieldItem {
  & + & {
    margin-top: 1em;
  }
}
