@use './src/common/style/global'as *;

.List {
  padding-bottom: 12px;
}

.Item {
  display: flex;
  align-items: center;
  font-size: 16px;
  padding: 0.5em 1em;

  &:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.04);
  }
}

.ItemIcon {
  display: inline-block;
  object-position: center;
  object-fit: contain;
  font-size: 1.15em;
  margin-right: 0.5em;
}

.ItemLabel {
  font-size: 1em;
}
