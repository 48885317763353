@use './src/common/style/global' as *;

$circle-size: 486px;

.view-pager {
  margin-bottom: auto;
}

// Bar
.view-pager__bar {
  counter-reset: section;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0 auto;
}

// Step

.view-pager__step {
  display: flex;
  font-size: 16px;
  flex: 1;
  justify-content: flex-end;

  position: relative;

  &::after,
  &::before {
    transition: all 0.5s;
    display: inline-block;
    content: "";
    height: 2px;
    background-color: #bdbdbd;

    left: calc(50% + 20px);
    right: calc(-50% + 20px);
    flex-grow: 1;
    margin-top: calc(1.85em / 2 - 4px);
  }

  &::before {
    left: calc(-50% + 20px);
    right: calc(50% + 20px);
  }

  &.disabled {
    pointer-events: none;

    .view-pager__step-icon {
      background-color: rgba(0, 0, 0, 0.38);
      color: $textColorSecondary;
    }
  }

  &.active {
    .view-pager__step-icon {
      background-color: #ffc700;
      color: $textColorPrimary;
    }
  }

  &.complete {
    .view-pager__step-icon {
      background-color: $green;
      color: $textColorSecondary;
    }
  }

  &.clickable {
    cursor: pointer;
  }

  &:last-child:after,
  &:first-child:before {
    display: none;
  }
}

.view-pager__step-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  border-radius: 50%;
  transition: all 0.5s;
  width: 1.85em;
  height: 1.85em;
  flex: 0 0 1.85em;

  background-color: rgba(0, 0, 0, 0.38);
  color: $textColorSecondary;
  overflow: hidden;

  &::before {
    counter-increment: section; /* Инкрементирует счётчик*/
    content: counter(section);
    text-align: center;
  }
}

.view-pager__step-title {
  margin-top: 1em;
  text-align: center;
  align-self: center;
}

// Actions

.view-pager__step-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex-grow: 0;
}

.view-pager__actions {
  display: flex;
  margin-top: 1em;
  justify-content: flex-end;
}

.view-pager__action {
  & + & {
    margin-left: 0.5em;
  }
}

// Other
.stepper-menu__item {
  overflow: hidden;

  & + & {
    margin-top: 6px;
  }
}

.stepper-menu__header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0.5em;
  border-radius: 0.25em;
  cursor: pointer;
  text-decoration: none;

  &.show {
    background-color: #d1f3ff;
  }

  &:hover {
    background-color: #d1f3ff;
  }
}

.stepper-menu__img {
  object-position: center;
  object-fit: contain;
  height: 18px;
  width: 18px;
}

.stepper-menu__title {
  margin-left: 8px;
  line-height: 120%;
  transform: translateY(5%);
}

.stepper-menu__content {
  position: relative;
  overflow: hidden;
  padding-left: 30px;
  max-height: 0;
  transition: max-height 1s cubic-bezier(0, 1.17, 0.32, 1.12);

  &:before {
    content: "";
    height: 100%;
    opacity: 1;
    width: 3px;
    background: #d1f3ff;
    position: absolute;
    left: calc(9px + 0.25em);
    top: 0;
    border-radius: 15px;
  }

  &.open {
    overflow: hidden;
    max-height: 100vh;
    transition: max-height 1s;
  }
}
