@use '../global' as *;

$app-padding-desktop: 12px 12px 12px 12px;
$app-padding-tablet: 12px 12px 12px 12px;

ion-icon {
  color: gray;
}

.app {
  background-color: #f5f5f5;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  flex-direction: column;
}

.app__aside-menu {
  overflow: hidden;
  flex: 0 0 var(--navbar-width);
  z-index: 1;
}

.app__footer {
  margin-top: 12px;
}

.app__content {
  flex: 1;
  overflow-y: scroll;
  overflow-x: hidden;

  display: flex;
  flex-direction: column;
  width: 100%;
}

.app__header {
  flex: 0 0 var(--header-height);
  position: relative;
  z-index: 2;
  overflow: hidden;
  padding: 0px 16px;
}

.app__body {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  overflow-y: hidden;
}

.app__main {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 18px 24px 0px;
}

.app__inner {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1 1;
}

hr {
  margin: 2em 0;
  padding: 0;
  height: 0;
  border: none;
  border-top: 2px solid #ced4da;
}

@media screen and (max-width: $bp-tablet) {
  .app__aside-menu {
    padding: 8px 6px;
  }

  .app__main {
    padding: 8px;
  }
}

@media screen and (max-width: $bp-mobile) {
  .app__aside-menu {
    //flex: 0 1 auto;
  }

  .app__header {
    flex-basis: var(--header-height);
    padding: 4px 8px;
  }

  .app__body {
    flex-direction: column-reverse;
  }

  .app__aside-menu {
    flex-basis: 50px;
    padding: 4px 8px;
  }
}
