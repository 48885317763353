@use './src/common/style/global'as *;

.progress-bar {
  height: 0.8em;
  display: flex;
  overflow: hidden;
  font-size: 16px;
  background-color: #e9ecef;
  border-radius: 0.25em;

  &_animated {
    .progress-bar__indicator {
      position: relative;

      &::after {
        content: "";
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: $white;
        animation: progress-active 2s ease infinite;
      }
    }
  }
}

.bg-happy-green {
  background-image: linear-gradient(to bottom, #00b09b, #96c93d) !important;
}

@keyframes progress-active {
  0% {
    opacity: 0.4;
    width: 0;
  }

  100% {
    opacity: 0;
    width: 100%;
  }
}
