@use '../global' as *;

.button {
  @extend %text;
  align-items: center;
  background-color: $gray;
  border: 1px solid $gray;
  border-radius: 1em;
  cursor: pointer;
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  padding: 0.8em;
  text-decoration: none;

  &:hover {
    text-decoration: none;
    background-color: $green;
    border: 1px solid $green;
    color: $white;
  }

  &_type {
    &_main {
      background-color: $white;
      border: 1px solid $black;
      border-radius: 1.6em;
      color: $black;
      padding: 0.8em 1.5em;
    }

    &_table {
      background-color: $white;
      border: 1px solid $black;
      border-radius: 1.2em;
      color: $black;
      padding: 0.5em 1em;
      font-size: 12px;
    }

    &_disabled {
      background-color: $white;
      border: 1px solid $gray;
      border-radius: 1.6em;
      color: $gray;
      padding: 0.8em 1.5em;
      cursor: none;

      &:hover {
        background-color: $white;
        border: 1px solid $gray;
        color: $gray;
      }

    }

    &_tabs {
      background-color: $white;
      border: 1px solid $black;
      border-radius: 1.2em;
      color: $black;
      padding: 0.5em 1em;
      font-size: 14px;
      flex-shrink: 0;

      @media screen and (max-width: 420px) {
        font-size: 12px;
      }
    }

    &_green {
      background-color: $green;
      border: 1px solid $green;
      border-radius: 1.6em;
      color: $white;
      padding: 0.5em 1em;
      font-size: 14px;
      flex-shrink: 0;
      display: block;
      margin: 5px 0;

      &:hover {
        background-color: $colorSecondaryDark;
        border: 1px solid $colorSecondaryDark;
      }
    }

    &_red {
      background-color: $red;
      border: 1px solid $red;
      border-radius: 1.6em;
      color: $white;
      padding: 0.5em 1em;
      font-size: 14px;
      flex-shrink: 0;
      display: block;
      margin: 5px 0;

      &:hover {
        background-color: $gray-red;
        border: 1px solid $gray-red;
      }
    }

    &_blue {
      background-color: $gray-blue;
      border: 1px solid $gray-blue;
      border-radius: 1.6em;
      color: $white;
      padding: 0.5em 1em;
      font-size: 14px;
      flex-shrink: 0;
      display: block;
      margin: 5px 0;

      &:hover {
        background-color: $blue;
        border: 1px solid $blue;
      }
    }
  }

  &__img {
    display: block;
    height: 18px;
    object-position: center;
    width: 18px;
  }

  &__txt {
    color: inherit;
    margin-left: 0.5em;
  }

  &.active {
    background-color: $green;
    border: 1px solid $green;
    border-radius: 1.6em;
    color: $white;

    &:hover {
      background-color: $colorSecondaryDark;
      border: 1px solid $colorSecondaryDark;
    }
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }
}
