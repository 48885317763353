@use './src/common/style/global' as *;

.page-spread {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 100vh;

  &__paper {
    width: 45%;
    height: 100%;
    min-width: 564px;
  }

  &__space {
    width: 55%;
    height: 100%;
  }
}

@media screen and (max-width: 895px) {
  .page-spread {
    .card {
      border-radius: 0;
    }

    &__paper {
      width: 100%;
      min-width: 100%;
    }

    &__space {
      display: none;
    }
  }
}
