@use './src/common/style/global' as *;

.logo-header {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 100%;
  text-decoration: none;
}

.logo-header__img {
  display: block;
  object-fit: contain;
  height: 32px;
}

.logo-header__label {
  display: block;
  margin-left: 12px;
}

@media screen and (max-width: $bp-mobile) {
  .logo-header__label {
    display: none;
  }

  .logo-header__img {
    height: 60%;
  }
}
