@use './src/common/style/global' as *;

.Wrapper {
  padding: 1em;
  padding-top: 0.5em;
  width: 100%;
  display: flex;
  align-items: center;
}

.SubmitBtn {
  margin-left: auto;
  display: flex;
  align-items: center;

  &.disable {
    pointer-events: none;
    opacity: 0.5;
  }
}

.Error {
  margin-right: 1em;
}
